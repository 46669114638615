import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ProductListTutModal = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleProceedClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const producttutorial = () => {
    try {
      localStorage.setItem("producttutorial", true);
      props.onHide();

      /// }
    } catch (err) {}
  };

  return (
    <>
      <Modal
        {...props}
        dialogClassName="login-tutorial-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="login-tutorial-main-wrapper">
            <div className="login-tutorial-main-wrap">
              {currentStep === 1 ? (
                <div className="product-close-btn">
                  <div className="close-drawer-wrap product-close-drawer">
                    <img
                      onClick={producttutorial}
                      // onClick={() => props.onHide()}
                      src="/images/icons/cross-icon.svg"
                    />
                  </div>
                </div>
              ) : (
                <div className="close-drawer-wrap">
                  <img
                    onClick={producttutorial}
                    // onClick={() => props.onHide()}
                    src="/images/icons/cross-icon.svg"
                  />
                </div>
              )}

              {currentStep === 1 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="product-tutorial-wrap">
                      <div className="product-tut-image">
                        <img src="/images/list-tut-1.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap product-tutorail-contant-wrap">
                        <h3> Welcome to the Product Lists Page </h3>
                        <p>
                          The Wholesaler Product List offers a complete overview
                          of pharmaceutical products within your wholesale
                          operations. Easily access vital product data, such as
                          lot information, quantities, and manufacturing
                          details, to streamline inventory control and maintain
                          compliance with industry regulations. Enhance
                          transparency and efficiency in your wholesaling
                          processes.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="product-tutorial-wrap">
                      <div className="product-tut-image mobile-first-product-list-img">
                        <img src="/images/mobile-list-tut-1.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap product-tutorail-contant-wrap">
                        <h3> Welcome to the Product Lists Page </h3>
                        <p>
                          The Wholesaler Product List offers a complete overview
                          of pharmaceutical products within your wholesale
                          operations. Easily access vital product data, such as
                          lot information, quantities, and manufacturing
                          details, to streamline inventory control and maintain
                          compliance with industry regulations. Enhance
                          transparency and efficiency in your wholesaling
                          processes.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 2 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/list-tut-2.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              {/* <div className="empty-dots-wrap"></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-list-tut-2.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-3"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              {/* <div className="empty-dots-wrap"></div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Third Step */}
              {currentStep === 3 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/list-tut-3.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-4"> 3 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            {/* <div className="empty-dots-wrap"></div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-list-tut-3.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-4"> 3 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            {/* <div className="empty-dots-wrap"></div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Forth Step */}
              {currentStep === 4 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/list-tut-4.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={producttutorial}>Go to List</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-5"> 4 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            {/* <div className="empty-dots-wrap"></div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-list-tut-4.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={producttutorial}>Go to List</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-5"> 4 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            {/* <div className="empty-dots-wrap"></div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductListTutModal;
