import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ShippingTutModal = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handleProceedClick = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const shiptutorial = () => {
    try {
      localStorage.setItem("shiptutorial", true);
      props.onHide();

      /// }
    } catch (err) {}
  };

  return (
    <>
      <Modal
        {...props}
        dialogClassName="login-tutorial-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="login-tutorial-main-wrapper">
            <div className="login-tutorial-main-wrap">
              {currentStep === 1 ? (
                <div className="product-close-btn">
                  <div className="close-drawer-wrap product-close-drawer">
                    <img
                      onClick={shiptutorial}
                      // onClick={() => props.onHide()}
                      src="/images/icons/cross-icon.svg"
                    />
                  </div>
                </div>
              ) : (
                <div className="close-drawer-wrap">
                  <img
                    onClick={shiptutorial}
                    // onClick={() => props.onHide()}
                    src="/images/icons/cross-icon.svg"
                  />
                </div>
              )}

              {currentStep === 1 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="product-tutorial-wrap">
                      <div className="product-tut-image">
                        <img src="/images/shiping-tut-1.png" alt="logo" />
                      </div>
                      <div className="login-tutorail-contant-wrap product-tutorail-contant-wrap">
                        <h3> Welcome to the Shipping Product Page </h3>
                        <p>
                          In pharmaceutical distribution, Hyperledger Fabric's
                          'Shipping' tool streamlines product dispatch. Specify
                          product, destination distributor, lot, tracking,
                          quantity, and ship date, securely recorded on the
                          blockchain for compliant, efficient shipments. <br />
                          <br /> With blockchain and Hyperledger Fabric at your
                          ERP's core, excel in pharmaceutical distribution, meet
                          regulations, ensure authenticity, and deliver safe
                          medications efficiently.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="empty-dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="product-tutorial-wrap">
                      <div className="product-tut-image">
                        <img
                          src="/images/mobile-shiping-tut-1.png"
                          alt="logo"
                        />
                      </div>
                      <div className="login-tutorail-contant-wrap product-tutorail-contant-wrap">
                        <h3> Welcome to the Shipping Product Page </h3>
                        <p>
                          In pharmaceutical distribution, Hyperledger Fabric's
                          'Shipping' tool streamlines product dispatch. Specify
                          product, destination distributor, lot, tracking,
                          quantity, and ship date, securely recorded on the
                          blockchain for compliant, efficient shipments. <br />
                          <br /> With blockchain and Hyperledger Fabric at your
                          ERP's core, excel in pharmaceutical distribution, meet
                          regulations, ensure authenticity, and deliver safe
                          medications efficiently.
                        </p>
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-1"> 1 </h4>
                            <div className="pages-dots-wrap">
                              <div className="dots-wrap active"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="dots-wrap"></div>
                              <div className="empty-dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Second Step */}
              {currentStep === 2 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/shiping-tut-2.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="empty-dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-shiping-tut-2.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>

                        <div className="login-tutorial-dots-wrap">
                          <div className="verified-pages-wrap">
                            <h4 className="active-2"> 2 </h4>
                            <div className="pages-dots-wrap">
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(1)}
                              ></div>
                              <div className="dots-wrap active"></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(3)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(4)}
                              ></div>
                              <div
                                className="dots-wrap"
                                onClick={() => setCurrentStep(5)}
                              ></div>
                              <div className="empty-dots-wrap"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Third Step */}
              {currentStep === 3 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/shiping-tut-3.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-3"> 3 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div className="empty-dots-wrap"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-shiping-tut-3.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-3"> 3 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div className="empty-dots-wrap"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Forth Step */}
              {currentStep === 4 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/shiping-tut-4.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-4"> 4 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div className="empty-dots-wrap"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-shiping-tut-4.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button onClick={handleProceedClick}>Continue</button>
                        </div>
                      </div>
                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-4"> 4 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(5)}
                            ></div>
                            <div className="empty-dots-wrap"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/***************** Tutorial Fifth Step */}
              {currentStep === 5 && (
                <>
                  <div className="desktop-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/shiping-tut-5.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button
                            className="create-new-btn"
                            onClick={shiptutorial}
                          >
                            <img
                              src="/images/icons/plus-white.svg"
                              alt="icon"
                            />
                            Create Shipping
                          </button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-5"> 5 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div className="empty-dots-wrap"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mobile-login-tutorial-wrap">
                    <div className="login-tutorial-second-wrap">
                      <div className="product-tutorial-demo">
                        <img src="/images/mobile-shiping-tut-5.png" alt="img" />
                      </div>

                      <div className="login-tutorial-bottom-wrap">
                        <div className="login-continue-button product-continue-button">
                          <button
                            className="create-new-btn"
                            onClick={shiptutorial}
                          >
                            <img
                              src="/images/icons/plus-white.svg"
                              alt="icon"
                            />
                            Create Shipping
                          </button>
                        </div>
                      </div>

                      <div className="login-tutorial-dots-wrap">
                        <div className="verified-pages-wrap">
                          <h4 className="active-5"> 5 </h4>
                          <div className="pages-dots-wrap">
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(1)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(2)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(3)}
                            ></div>
                            <div
                              className="dots-wrap"
                              onClick={() => setCurrentStep(4)}
                            ></div>
                            <div className="dots-wrap active"></div>
                            <div className="empty-dots-wrap"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShippingTutModal;
