/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useLocation } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import ProductListTutModal from "../../components/Modals/ProductListTutModal";
import ShippingTutModal from "../../components/Modals/ShippingTutModal";
import ShipHistoryTutModal from "../../components/Modals/ShipHistoryTutModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

/// Image
//import profile from "../../../images/profile/pic1.jpg";
//import plus from "../../../images/plus.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [showShipHistoryModal, setShowShipHistoryModal] = useState(false);
  const location = useLocation();
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const history = useHistory();
  function onLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.clear();
    history.push("/login");
    window.location.reload();
  }

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);

    // Add a listener to detect route changes
    const unlisten = history.listen(() => {
      // Remove the 'menu-toggle' class when the route changes
      aaa.classList.remove("menu-toggle");
    });

    // Clean up the listener when the component unmounts
    return () => {
      btn.removeEventListener("click", toggleFunc);
      handleheartBlast.removeEventListener("click", heartBlast);
      unlisten(); // Clean up the route change listener
    };
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = location.pathname;
  console.log("path", path);

  const shiptutorial = () => {
    // props.onHide();
    try {
      const tutorials = localStorage.getItem("shiptutorial");
      console.log("tutorials", tutorials);
      if (tutorials == null) {
        setShowShipHistoryModal(true);
      } else {
        setShowShipHistoryModal(false);
      }

      // setShowShipHistoryModal(true);
    } catch (err) {}
  };

  ///////////////
  const productutorial = () => {
    // props.onHide();
    try {
      const tutorials = localStorage.getItem("producttutorial");
      console.log("producttutorial", tutorials);
      if (tutorials == null) {
        setShowTutorialModal(true);
      } else {
        setShowTutorialModal(false);
      }

      // setShowShipHistoryModal(true);
    } catch (err) {}
  };

  /// Active menu
  let deshBoard = [
      "/",
      // "create-product",
      // "Shipping",
      // "inventory",
      // "shiping-history",
    ],
    app = [
      "app-profile",
      "post-details",
      "app-calender",
      "email-compose",
      "email-inbox",
      "email-read",
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "post-details",
      "ecom-product-detail",
    ],
    email = ["email-compose", "email-inbox", "email-read"],
    shop = [
      "ecom-product-grid",
      "ecom-product-list",
      "ecom-product-list",
      "ecom-product-order",
      "ecom-checkout",
      "ecom-invoice",
      "ecom-customers",
      "ecom-product-detail",
    ],
    charts = [
      "chart-rechart",
      "chart-flot",
      "chart-chartjs",
      "chart-chartist",
      "chart-sparkline",
      "chart-apexchart",
    ],
    bootstrap = [
      "ui-accordion",
      "ui-badge",
      "ui-alert",
      "ui-button",
      "ui-modal",
      "ui-button-group",
      "ui-list-group",
      "ui-media-object",
      "ui-card",
      "ui-carousel",
      "ui-dropdown",
      "ui-popover",
      "ui-progressbar",
      "ui-tab",
      "ui-typography",
      "ui-pagination",
      "ui-grid",
    ],
    plugins = [
      "uc-select2",
      "uc-nestable",
      "uc-sweetalert",
      "uc-toastr",
      "uc-noui-slider",
      "map-jqvmap",
      "uc-lightgallery",
    ],
    redux = ["redux-form", "redux-wizard", "todo"],
    widget = ["widget-basic"],
    forms = [
      "form-element",
      "form-wizard",
      "form-editor-summernote",
      "form-pickers",
      "form-validation-jquery",
    ],
    table = ["table-bootstrap-basic", "table-datatable-basic"],
    pages = [
      "page-register",
      "page-login",
      "page-lock-screen",
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ],
    error = [
      "page-error-400",
      "page-error-403",
      "page-error-404",
      "page-error-500",
      "page-error-503",
    ];
  return (
    <>
      <ProductListTutModal show={showTutorialModal} onHide={productutorial} />
      <ShippingTutModal show={showShipHistoryModal} onHide={shiptutorial} />
      <div
        className={`deznav 
      ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? scrollPosition > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="deznav-scroll">
          {/* <div className="sidebar-logo-wrapper">
            <img
              className="hyper-big-logo"
              src="/images/icons/manu-logo.png"
              alt="logo"
            />
            <img
              className="manu-small-logo"
              src="/images/icons/manu-small-logo.png"
              alt="logo"
            />
          </div> */}

          <MM className="metismenu" id="menu">
            <div className="whole-saler-mobile-logo">
              <img src="/images/icons/wholesaler-logo.svg" alt="logo" />
            </div>
            <li
              className={`${
                path === "/" || path === "/dashboard" ? "mm-active" : ""
              }`}
            >
              <Link className="has-arrow ai-icon" to="/">
                {path === "/" ? (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/dashbord-icon.svg"
                    alt="icon"
                  />
                ) : (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/dashbord-gray.svg"
                    alt="icon"
                  />
                )}
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li
              className={`${path === "/inventory" ? "mm-active" : ""}`}
              onClick={productutorial}
            >
              <Link className="has-arrow ai-icon" to="/inventory">
                {path === "/inventory" ? (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/product-active.svg"
                    alt="icon"
                  />
                ) : (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/product-list.svg"
                    alt="icon"
                  />
                )}
                <span className="nav-text"> Products List </span>
              </Link>
            </li>
            <li
              className={`${path === "/Shipping" ? "mm-active" : ""}`}
              onClick={shiptutorial}
            >
              <Link className="has-arrow ai-icon" to="/Shipping">
                {path === "/Shipping" ? (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/shipping-active.svg"
                    alt="icon"
                  />
                ) : (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/shipping-gray.svg"
                    alt="icon"
                  />
                )}
                <span className="nav-text">Shipping</span>
              </Link>
            </li>

            <li
              className={`${path === "/shiping-history" ? "mm-active" : ""}`}
              // onClick={() => setShowShipHistoryModal(true)}
            >
              <Link className="has-arrow ai-icon" to="/shiping-history">
                {path === "/shiping-history" ? (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/shipping-hist-active.svg"
                    alt="icon"
                  />
                ) : (
                  <img
                    className="sidebar-menu-icon"
                    src="/images/icons/shipping-history.svg"
                    alt="icon"
                  />
                )}
                <span className="nav-text"> Shipping History</span>
              </Link>
            </li>
          </MM>
          <div className="copyright">
            <p>
              {/* <strong>Innap Hotel Admin</strong> © 2021 All Rights Reserved */}
            </p>
            <p className="fs-12">
              <span className="heart"></span>
            </p>
          </div>

          <div className="sidebar-bottom-wrapper small-screen-bottom-wrapper">
            <div className="sidebar-logout-wrapper">
              <div className="sidebar-logout-wrap">
                <img
                  onClick={onLogout}
                  src="/images/icons/logout-icon.svg"
                  alt="img"
                />
                <p onClick={onLogout}> Logout </p>
              </div>
            </div>
          </div>

          <div className="sidebar-bottom-wrapper desktop-sidebar-bottom">
            <div className="sidebar-logout-wrapper">
              <div className="sidebar-logout-wrap">
                <img
                  onClick={onLogout}
                  src="/images/icons/logout-icon.svg"
                  alt="img"
                />
                <p onClick={onLogout}> Logout </p>
              </div>
            </div>
            {/* <div className="gyan-solution-wrap">
              <a href="https://www.gyan.solutions/" target="_blank">
                <img
                  className="gyan-big-logo"
                  src="/images/icons/gyan-logo.png"
                  alt="logo"
                />
              </a>
              <a href="https://www.gyan.solutions/" target="_blank">
                <img
                  className="gyan-small-logo"
                  src="/images/icons/gyan-small-logo.png"
                  alt="logo"
                />
              </a>
            </div>
            <p className="gyan-solution-copyright">
              © 2023 Gyan.Solutions, all right reserved
            </p> */}
          </div>
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default SideBar;
