import React, { Fragment, useState, useEffect } from "react";
import { serviceGet, servicePost } from "../../../services/api";
import { Row, Col, Card, Table, Badge } from "react-bootstrap";
import arrow from "../../../images/down-arrow.png";
import ShipingDrawer from "./Drawer/ShipingDrawer";
import { Drawer } from "antd";
import moment from "moment";
import GyanConsulting from "./GyanConsulting";
import LoginTabs from "./LoginTabs";
const ShipingHistory = () => {
  const [products, setProducts] = useState([]);
  const [shipData, setShipData] = useState([]);
  const [filteredShip, setFilteredShip] = useState([]);
  const [selected, setSelected] = useState("Products");
  const [filterAmount, setFilterAmount] = useState("");
  const [filterLot, setFilterLot] = useState("");
  const [isActive, setIsActive] = useState(false);

  const [open, setOpen] = useState(false);

  const [shipinfo, setshipinfo] = useState();

  const showDrawer = async (productname) => {
    console.log("productname", productname);
    const getHistory = await servicePost("api/getShipmantdata", {
      product: productname,
      from: "Wholesaler",
    });
    console.log("getHistory=", getHistory);
    setshipinfo(getHistory);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // get all products
  const getAllShips = async () => {
    try {
      const data = await servicePost("api/getAllShip",{
        from:"Wholesaler"
      });
      setShipData(data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllShips();
  }, []);

  // get all products
  const getAllProducts = async () => {
    try {
      const allProducts = await serviceGet("api/distributor/allproducts");
      setProducts(allProducts);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get uniqueProducts..
  const uniqueProducts = shipData.reduce((acc, current) => {
    if (!acc[current.product]) {
      acc[current.product] = { ...current }; // Copy the current object
    } else {
      // If the product already exists, add the amount to the existing object
      acc[current.product].amount += current.amount;
    }
    return acc;
  }, {});
  const uniqueProduct = Object.values(uniqueProducts);

  /// HandleKeyPress
  function handleKeyPress(event) {
    const regex = /^[0-9\b]+$/; // allows only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // Filter Product basis of Product lot and amount
  const filterTask = () => {
    const allData = [];
    var selectedProduct = selected == "Products" ? "" : selected;
    uniqueProduct &&
      uniqueProduct
        .filter((ele) => {
          if (
            selectedProduct === "" &&
            filterAmount === "" &&
            filterLot == ""
          ) {
            return ele;
          }
          // One filled fill and two empty
          else {
            if (selectedProduct && !filterAmount && !filterLot) {
              return ele.product === selectedProduct;
            } else if (filterAmount && !selectedProduct && !filterLot) {
              return Number(ele.amount) === Number(filterAmount);
            } else if (!filterAmount && !selectedProduct && filterLot) {
              return Number(ele.lot) === Number(filterLot);
            }
            // if two filled fill Product check
            else if (!filterLot && selectedProduct && filterAmount) {
              return (
                ele.product === selectedProduct &&
                Number(ele.amount) === Number(filterAmount)
              );
            } else if (filterLot && selectedProduct && !filterAmount) {
              return (
                Number(ele.lot) === Number(filterLot) &&
                ele.product === selectedProduct
              );
            }
            // if one filled fill
            else if (selectedProduct && !filterLot) {
              return (
                ele.amount === filterAmount && ele.product === selectedProduct
              );
            } else if (filterLot && !selectedProduct) {
              return (
                Number(ele.lot) === Number(filterLot) &&
                Number(ele.amount) === Number(filterAmount)
              );
            } else if (filterAmount && selectedProduct && filterLot) {
              return (
                Number(ele.amount) === Number(filterAmount) &&
                ele.product === selectedProduct &&
                Number(ele.lot) === Number(filterLot)
              );
            }
          }
        })
        .map((data) => {
          allData.push({
            product: data.product,
            pharma: data.organization,
            skuNumber: data.skuNumber,
            shipper: data.shipper,
            lot: data.lot,
            amount: data.amount,
            expireDate: data.expireDate,
            trackingNo: data.trackingNo,
            from: data.from,
            to: data.to,
           // transactionId: data.transactionId,
          });
        });
    setFilteredShip(allData);
  };

  useEffect(() => {
    filterTask();
  }, [shipData, filterAmount, filterLot, selected]);

  // filtered ShipData
  const filterShipData = filteredShip?.filter((data) => {
    return data.from == "Wholesaler";
  });
  return (
    <Fragment>
      <Drawer
        title="Product Details"
        width={1530}
        placement="right"
        // onClose={onClose}
        open={open}
      >
        <div className="drawer-header-main-wrap">
          <div className="drawer-header-wrap">
            <div className="drawer-heading-wrap">
              <h3> Product Details </h3>
            </div>
            <div className="close-button-wrap">
              <img
                onClick={onClose}
                src="/images/icons/cross-icon.svg"
                alt="icon"
              />
            </div>
          </div>
        </div>
        <ShipingDrawer shipinfo={shipinfo} />
      </Drawer>
      {/* <PageTitle activeMenu="Table" motherMenu="Bootstrap" /> */}
      {/* <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>
                Filters
                <div className="filter-table">
                  <div className="amount-filter">
                    <input
                      placeholder="Amount filter"
                      type="number"
                      onKeyPress={handleKeyPress}
                      onChange={(e) => setFilterAmount(e.target.value)}
                      min={0}
                    />
                  </div>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Shipper</th>
                    <th>Sku Amount</th>
                    <th>Product</th>
                    <th>Lot</th>
                    <th>Pharma</th>
                    <th>Amount</th>
                    <th>Tracking number</th>
                    <th>Expire date</th>
                    <th>Ship date</th>
                    <th>TxId</th>
                  </tr>
                </thead>
                <tbody>
                  {filterShipData?.length === 0 ? (
                    <tr>
                      <td
                        style={{ textAlign: "center", fontSize: "18px" }}
                        colSpan={9}
                      >
                        No Data Found
                      </td>
                    </tr>
                  ) : (
                    filterShipData?.map((data, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>{data.shipper}</td>
                            <td>{data.skuNumber}</td>
                            <td>{data.product}</td>
                            <td>{data.lot}</td>
                            <td>{data.pharma}</td>
                            <td>{data.amount}</td>
                            <td>{data.trackingNo}</td>
                            <td>
                              {moment(data.shipDate).format("YYYY-MM-DD")}
                            </td>
                            <td>
                              {moment(data.expireDate).format("YYYY-MM-DD")}
                            </td>
                            <td>{data.transactionId}</td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      <div className="header-main-wrapper">
        <div className="header-main-wrap">
          <div className="create-new-product-btn">
            <div className="select-box-wrapper inventory-filter">
              <div className="react-dropdown">
                <div
                  className="react-dropdown-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsActive(!isActive);
                  }}
                >
                  <div className="selected-item">
                    <p> {selected} </p>
                  </div>

                  <img
                    className={
                      isActive
                        ? "dropdown-down-arrow rotate-arrow"
                        : "dropdown-down-arrow"
                    }
                    src={arrow}
                    alt="arrow"
                  />
                </div>
                {isActive && (
                  <div className="dropdown-content">
                    <div
                      onClick={(e) => {
                        setSelected("Products");
                        setIsActive(false);
                      }}
                      className="dropdown-item"
                    >
                      <p> Clear filter </p>
                    </div>
                    {uniqueProduct &&
                      uniqueProduct.map((option, index) => {
                        return (
                          <Fragment key={index}>
                            <div
                              onClick={(e) => {
                                setSelected(option.product);
                                setIsActive(false);
                              }}
                              className="dropdown-item"
                            >
                              <p> {option.product} </p>
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-list-table-wrapper">
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                <img
                  className="table-product-icon"
                  src="/images/icons/input-1.svg"
                  alt="icon"
                />
                Product
              </th>
              <th>Quantity</th>
              <th> Sku Numbers </th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {filterShipData?.length === 0 ? (
              <tr>
                <td
                  style={{ textAlign: "center", fontSize: "18px" }}
                  colSpan={8}
                >
                  No Data Found
                </td>
              </tr>
            ) : (
              filterShipData?.map((data, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td> {data.product} </td>
                      <td className="color-primary"> {data.amount} </td>
                      <td>{data.skuNumber}</td>
                      <td>
                        <img
                          onClick={() => showDrawer(data.product)}
                          src="/images/icons/view-icon.svg"
                          alt="icon"
                        />
                      </td>
                    </tr>
                  </>
                );
              })
            )}
          </tbody>
        </Table>
        <LoginTabs />
      </div>

      <GyanConsulting />
    </Fragment>
  );
};

export default ShipingHistory;
