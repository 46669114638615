import React, { useState, Fragment } from "react";
import arrow from "../../../images/down-arrow.png";
import { serviceGet, servicePost } from "../../../services/api";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import { useEffect } from "react";
import moment from "moment";
import ShippingTutModal from "../Modals/ShippingTutModal";
import { DatePicker, Space } from "antd";
import GyanConsulting from "./GyanConsulting";

const Shipping = () => {
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("Select Product");
  const productVal = selected == "Select Product" ? "" : selected;

  // Lot dropdown
  const [isLotActive, setIsLotActive] = useState(false);
  const [selectedLot, setSelectedLot] = useState("Select Lots");
  const lot = selectedLot == "Select Lots" ? "" : selectedLot;

  // Distributor dropdown
  const [isDistributorActive, setIsDistributorActive] = useState(false);
  const [selectedDistributor, setSelectedDistributor] =
    useState("Select Pharma");
  const Wholesaler =
    selectedDistributor == "Select Pharma" ? "" : selectedDistributor;

  const [users, setUsers] = useState([]);
  const [shipDate, setShipDate] = useState();
  const [skuNumber, setskuNumber] = useState();
  const [expireDate, setExpireDate] = useState();
  const [shipData, setShipData] = useState({
    
    amount: null,
    shipper: "",
    trackingNo: "",
  });

  // Get user form localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  const {  amount, shipper, trackingNo } = shipData;

  const history = useHistory();
  const refOne = useRef(null);
  const today = new Date().toISOString().split("T")[0];

  // Access Dom and When Click Outside Close DropDowns...
  useEffect(() => {
    const hideOnClickOutside = (e) => {
      if (refOne.current && !refOne.current.contains(e.target)) {
        setIsActive(false);
        setIsLotActive(false);
        setIsDistributorActive(false);
      }
    };

    document.addEventListener("click", hideOnClickOutside);
    return () => {
      document.removeEventListener("click", hideOnClickOutside);
    };
  }, []);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setShipData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setSelectedLot("Select Lots");
  }, [selected]);

  // get all products
  const getAllProducts = async () => {
    try {
      const allProducts = await serviceGet("api/wholesaler/allproducts");
      setProducts(allProducts);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  // get all users
  const getAllUsers = async () => {
    try {
      const allusers = await serviceGet("api/getUsers");
      setUsers(allusers);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  // filterData basis of userEmail
  const filterData = products?.filter((data) => {
    return data.organization == userData.email;
  });

  // get uniqueProducts..
  const uniqueProducts = products?.reduce((acc, current) => {
    if (!acc[current.product]) {
      acc[current.product] = current;
    }
    return acc;
  }, {});
  const uniqueProduct = Object.values(uniqueProducts);

  // get Selected Product value...
  const selectedProduct = products.filter((data) => {
    return data.product == productVal && data.lot == lot;
  });

  useEffect(() => {
    if (selectedProduct) {
      setExpireDate(
        selectedProduct[0]?.expireDate ? selectedProduct[0]?.expireDate : ""
      );
    }
  }, [lot]);

  // Create Ship
  const createShipHandler = async () => {
    function formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }

    if (formatDate(Date.now()) > formatDate(expireDate)) {
      alert("This Product expired");
      return;
    }

    if (
      productVal == "" ||
      lot == "" ||
      Wholesaler == "" ||
      // skuNumber == "" ||
      amount == "" ||
      shipper == "" ||
      shipDate == "" ||
      expireDate == "" ||
      trackingNo == ""
    ) {
      alert("Please fill all Fields");
      return;
    }

    try {
      setIsLoading(true);
      const convertdateformat = new Date(expireDate);
      const convertedDate = convertdateformat.toISOString().slice(0, 10);
      console.log("convertedDate", convertedDate);
      const data = await servicePost("api/createShip", {
        product: productVal,
        organization: Wholesaler,
        skuNumber: skuNumber,
        lot: lot,
        amount: amount,
        shipper: shipper,
        trackingNo: trackingNo,
        shipDate: shipDate,
        expireDate: convertedDate,
        from: "Wholesaler",
        to: "Pharma",
      });

      if (data) {
        history.push("/shiping-history");
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        alert(err.response.data.message);
        setIsLoading(false);
      } else {
        alert("Something went wrong");
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  /// HandleKeyPress
  function handleKeyPress(event) {
    const regex = /^[0-9\b]+$/; // allows only numbers and backspace
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // hadleKey allow only Char
  function handleKeyCharPress(event) {
    const regex = /^[a-zA-Z ]+$/; // allows only characters and space
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  // const onChange: DatePickerProps["onChange"] = (date, dateString) => {
  //   console.log(dateString);
  // };

  // Increment and Decrement Quantity
  const incrementAmount = () => {
    setShipData((prevData) => ({
      ...prevData,
      amount: Number(prevData.amount) + 1,
    }));
  };

  const decrementAmount = () => {
    if (shipData.amount > 0) {
      setShipData((prevData) => ({
        ...prevData,
        amount: Number(prevData.amount) - 1,
      }));
    }
  };

  return (
    <>
      <ShippingTutModal
        show={showTutorialModal}
        onHide={() => setShowTutorialModal(false)}
      />

      <div className="create-product-main-wraaper">
        <div className="mobile-create-tutorial-wrap mobile-shipping-tutorial-wrap">
          <div className="tutorial-wrap-btn">
            <img
              onClick={() => setShowTutorialModal(true)}
              src="/images/icons/tutorial-icon.png"
              alt="icon"
            />
            <p> Tutorials </p>
          </div>
        </div>
        <div className="create-product-main-wrap">
          <div className="create-product-left-wrap">
            <div className="create-product-left">
              <div className="create-product-left-img">
                <img src="/images/shipping-img.png" alt="img" />
              </div>
              <div className="create-product-left-contant">
                {/* <h3>Welcome to the Shipping Creation Page </h3> */}

                <p>
                  In pharmaceutical distribution, Hyperledger Fabric's
                  'Shipping' tool streamlines product dispatch. Specify product,
                  destination distributor, lot, tracking, quantity, and ship
                  date, securely recorded on the blockchain for compliant,
                  efficient shipments. <br /> <br /> With blockchain and
                  Hyperledger Fabric at your ERP's core, excel in pharmaceutical
                  distribution, meet regulations, ensure authenticity, and
                  deliver safe medications efficiently.
                </p>

                <div className="toutorial-icon-wrap">
                  <img
                    onClick={() => setShowTutorialModal(true)}
                    src="/images/icons/tutorial-icon.png"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="create-product-right-wrap shipping-product-form">
            <div className="card1">
              <div className="card-body">
                <form>
                  <div className="create-product-form row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Product</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsActive(!isActive);
                              setIsLotActive(false);
                              setIsDistributorActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {selected}
                              </p>
                            </div>

                            <img
                              className={
                                isActive
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src="/images/icons/down-arrow.svg"
                              alt="arrow"
                            />
                          </div>
                          {isActive && (
                            <div className="dropdown-content" ref={refOne}>
                              {uniqueProduct &&
                                uniqueProduct?.map((option, index) => {
                                  return (
                                    <Fragment key={index}>
                                      <div
                                        onClick={(e) => {
                                          setSelected(option.product);
                                          setskuNumber(option.skuNumber);
                                          setIsActive(false);
                                        }}
                                        className="dropdown-item"
                                      >
                                        <p> {option.product} </p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Shipper Name
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="shipper"
                          className="input-field-redux"
                          placeholder="Enter Shipper name"
                          onKeyPress={handleKeyCharPress}
                          onChange={onChangeHandler}
                          value={shipper}
                        />

                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/shipper-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Pharma</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsDistributorActive(!isDistributorActive);
                              setIsLotActive(false);
                              setIsActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/pharma-input.svg"
                                    alt="icon"
                                  />
                                </div>
                                {selectedDistributor}
                              </p>
                            </div>

                            <img
                              className={
                                isDistributorActive
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src="/images/icons/down-arrow.svg"
                              alt="arrow"
                            />
                          </div>
                          {isDistributorActive && (
                            <div
                              className="dropdown-content distributor-ref"
                              ref={refOne}
                            >
                              {users &&
                                users
                                  ?.filter((data) => {
                                    return data.organization == "Pharma";
                                  })
                                  .map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelectedDistributor(
                                              option.email
                                            );
                                            setIsDistributorActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p> {option.email} </p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        sku number
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input">
                        <input
                          name="skuNumber"
                          type="number"
                          placeholder="Enter sku number"
                          onKeyPress={handleKeyPress}
                          className="input-field-redux"
                          onChange={onChangeHandler}
                          value={skuNumber}
                          min={0}
                        />
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-5.svg" alt="icon" />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label> Tracking number</label>
                      <div className="create-product-input">
                        <input
                          name="trackingNo"
                          className="input-field-redux"
                          placeholder="Enter Your Text"
                          onChange={onChangeHandler}
                          value={trackingNo}
                        />
                        <div className="input-icon-wrap">
                          <img
                            src="/images/icons/traking-input.svg"
                            alt="icon"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="select-box-wrapper shipping-dropdown">
                        <label>Lots</label>
                        <div className="react-dropdown">
                          <div
                            className="react-dropdown-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsLotActive(!isLotActive);
                              setIsActive(false);
                              setIsDistributorActive(false);
                            }}
                          >
                            <div className="selected-item">
                              <p>
                                <div className="input-icon-wrap">
                                  <img
                                    src="/images/icons/input-1.svg"
                                    alt="icon"
                                  />
                                </div>
                                {productVal
                                  ? selectedLot
                                  : "Select product first"}
                              </p>
                            </div>

                            <img
                              className={
                                isLotActive && productVal
                                  ? "dropdown-down-arrow rotate-arrow"
                                  : "dropdown-down-arrow"
                              }
                              src="/images/icons/down-arrow.svg"
                              alt="arrow"
                            />
                          </div>
                          {isLotActive && productVal && (
                            <div className="dropdown-content" ref={refOne}>
                              {filterData &&
                                filterData
                                  .filter((data) => {
                                    return data.product == selected;
                                  })
                                  .map((option, index) => {
                                    return (
                                      <Fragment key={index}>
                                        <div
                                          onClick={(e) => {
                                            setSelectedLot(option.lot);
                                            setIsLotActive(false);
                                          }}
                                          className="dropdown-item"
                                        >
                                          <p> {option.lot} </p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Quantity
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <div className="create-product-input quantity-input-wrap">
                        <input
                          name="amount"
                          className="input-field-redux"
                          type="number"
                          onChange={onChangeHandler}
                          onKeyPress={handleKeyPress}
                          value={amount}
                          min={0}
                        />
                        <div className="increment-icon">
                          <img
                            onClick={incrementAmount}
                            src="/images/icons/increment-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="decrement-icon">
                          <img
                            onClick={decrementAmount}
                            src="/images/icons/decrement-icon.svg"
                            alt="icon"
                          />
                        </div>
                        <div className="input-icon-wrap">
                          <img src="/images/icons/input-6.svg" alt="icon" />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Ship date
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <input
                        type="date"
                        min={today}
                        onChange={(e) => setShipDate(e.target.value)}
                      />
                      {/* <DatePicker onChange={onChange} /> */}
                    </div>

                    {/* <div className="col-sm-12 col-md-6 col-lg-6">
                      <label>
                        Expire date
                        <img src="/images/icons/info-icon.svg" alt="icon" />
                      </label>
                      <input
                        type="date"
                        name="expire date"
                        className="input-field-redux expire-date-input"
                        value={
                          expireDate
                            ? moment(expireDate).format("YYYY-MM-DD")
                            : ""
                        }
                        disabled
                      />
                    </div> */}

                    {expireDate ? (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label>
                          Expire date
                          <img src="/images/icons/info-icon.svg" alt="icon" />
                        </label>
                        <input
                          type="date"
                          name="expire date"
                          className="input-field-redux expire-date-input"
                          value={
                            expireDate
                              ? moment(expireDate).format("YYYY-MM-DD")
                              : ""
                          }
                          disabled
                        />
                      </div>
                    ) : (
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <label> Expire date </label>
                        <div className="create-product-input">
                          <input
                            className="input-field-redux"
                            value="Select Lot first"
                          />
                          <div className="input-icon-wrap">
                            <img src="/images/icons/input-8.svg" alt="icon" />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-sm-12 text-center">
                      <button
                        className="create-product-btn"
                        onClick={createShipHandler}
                        disabled={isLoading}
                      >
                        {isLoading ? "Processing..." : "Proceed"}
                      </button>

                      <div className="tutorial-wrap-btn tutorial-tab-etc">
                        <img
                          onClick={() => setShowTutorialModal(true)}
                          src="/images/icons/tutorial-icon.png"
                          alt="icon"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GyanConsulting />
    </>
  );
};

export default Shipping;
